<template>
    <div class="see-all">
        <div class="container-fluid">
            <div class="breadcumb d-flex align-items-center">
                <h5 class="font-weight-normal" type="button" @click="$router.go(-1)">Template Quiz</h5>
                <b-icon icon="chevron-right"></b-icon>
                <h5>{{ type }}</h5>
            </div>
            <div class="header mt-5 d-flex justify-content-between">
                <label for="search" class="text-center search">
                    <b-form-input v-model="filter" placeholder="Cari Konten"></b-form-input>
                </label>
                <div class="d-inline-flex align-items-center pr-5">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                </div>
            </div>
            <div v-if="loading" class="div mx-3">
                <div class="row" id="allData">
                    <div v-for="index in 4" :key="index" class="col-md-3">
                        <div class="card mr-2 mt-4 mx-2 my-2">
                            <b-skeleton-img card-img="top"></b-skeleton-img>
                            <div class="card-body text-left">
                                <div class="d-flex justify-content-between mb-2">
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                    <b-skeleton animation="wave" width="30%"></b-skeleton>
                                </div>
                                <div class="d-flex mb-2">
                                    <b-skeleton animation="wave" width="50%"></b-skeleton>
                                </div>
                                <b-skeleton animation="wave" width="80%"></b-skeleton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="div mx-3">
                <div class="row" id="allData">
                    <div v-for="(dataTrend, index) in dataList" :key="index" class="col-md-3">
                        <div class="card mr-2 mt-4 mx-2 my-2">
                            <img
                                v-if="dataTrend.images_cover == null"
                                src="@/assets/images/default-cover.png"
                                alt=""
                                class="img-form card-img-top"
                                type="button" @click="detailQuiz(dataTrend._id, dataTrend.author_quiz)"
                            />
                            <img v-else :src="dataTrend.images_cover" alt="" class="card-img-top" type="button" @click="detailQuiz(dataTrend._id, dataTrend.author_quiz)"/>
                            <div class="card-body text-left">
                                <div class="d-flex justify-content-between align-items-start">
                                    <h5 class="card-title text-truncate">{{ dataTrend.quiz_name }}</h5>
                                    <b-dropdown size="sm"  variant="link" toggle-class="text-decoration-none text-dark p-0" no-caret>
                                        <template #button-content>
                                            <b-icon icon="three-dots-vertical"></b-icon>
                                        </template>
                                        <b-dropdown-item @click="share(dataTrend._id, dataTrend.author_quiz)" class="d-flex align-items-center"><img src="@/assets/images/icons/share-icon.svg" alt="" class="user-svg mr-2">Share</b-dropdown-item>
                                        <b-dropdown-item class="d-flex align-items-center" @click="detailQuiz(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/duplicate-icon.svg" alt="" class="user-svg mr-2">Duplicate</b-dropdown-item>
                                        <b-dropdown-item class="d-flex align-items-center" @click="modalReport(dataTrend._id, dataTrend.author_quiz)"><img src="@/assets/images/icons/report-icon.svg" alt="" class="user-svg mr-2">Report</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="d-flex justify-content-start mb-2">
                                    <div class="d-flex align-items-center mr-4">
                                        <img
                                        src="@/assets/images/icons/ic_play_grup_grey.svg"
                                        alt=""
                                        class="user-svg"
                                        />
                                    <p class="ml-1 mb-0 text-dark">{{dataTrend.total_play}}</p>
                                    </div>
                                    <div class="d-flex mr-4">
                                        <img
                                        src="@/assets/images/icons/ic_question_grup_grey.svg"
                                        alt=""
                                        class="user-svg"
                                        />
                                    <p class="ml-1 mb-0 text-dark">{{ dataTrend.total_question }}</p>
                                    </div>
                                    <div class="d-flex">
                                        <img
                                        src="@/assets/images/icons/ic_clock_grup_grey.svg"
                                        alt=""
                                        class="user-svg"
                                        />
                                    <p class="ml-1 mb-0 text-dark">{{getMinQuiz(dataTrend.total_question)}}min</p>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-between align-items-center mt-3">
                                    <div class="creator-info d-flex align-items-center">
                                        <img
                                            v-if="dataTrend.author != null"
                                            src="@/assets/images/logo/soca-logo.png"
                                            alt=""
                                            class="ml-2 rounded-circle"
                                        />
                                        <img
                                            v-if="dataTrend.data_creator != null"
                                            :src="dataTrend.data_creator[0].picture != null ? dataTrend.data_creator[0].picture : 'https://ui-avatars.com/api/?background=405DC3&color=fff&name='+dataTrend.data_creator[0].name "
                                            alt=""
                                            class="ml-1 rounded-circle"
                                        />
                                        <p v-if="dataTrend.author != null" class="text-dark ml-1 mb-0">{{ cutName(dataTrend.author)}},</p>
                                        <p v-if="dataTrend.creator_name != null" class="text-dark ml-1 mb-0">{{ cutName(dataTrend.creator_name)}},</p>
                                        <p class="ml-1 mb-0 text-truncate mt-1" style="font-size: 12px">{{ getDate(dataTrend.createdAt) }}</p>
                                    </div>
                                    <div class="d-flex align-items-end">
                                        <img src="@/assets/images/logo/start-icon.svg" alt="" class="icon-start">
                                        <p class="text-dark ml-1 mb-0">{{dataTrend.rating ? dataTrend.rating : '0'}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination-seeall container-md mt-3 d-flex justify-content-between">
                <p>Showing {{dataList.length}} of {{quiz.length}} entries</p>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="rows"
                    :per-page="perPage"
                    aria-controls="allData"
                    prev-text="Prev"
                    next-text="Next"
                ></b-pagination>
            </div>
        </div>
        <b-modal id="report-quiz" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
            <div class="d-flex justify-content-between my-4 mx-4">
                <div class="text-left d-flex align-items-center">
                    <img src="@/assets/images/icons/report-icon.svg" alt="" class="mr-3 mt-1">
                    <h3 class="font-weight-bold">Laporkan</h3>
                </div>
                <div class="text-right">
                    <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('report-quiz')"><b-icon icon="x"></b-icon></button>
                </div>
            </div>
            <div class="mx-4">
                <div>
                    <h4>Mengapa kamu melaporkan quiz ini?</h4>
                    <div v-for="(data, index) in optionReport" :key="index" class="mt-3">
                        <div class="form-check d-flex align-items-center my-2">
                            <input class="form-check-input" type="radio" name="exampleRadios" :id="'report' + index" v-model="formReport.report" :value="data.text" checked>
                            <label class="form-check-label ml-2" :for="'report' + index">
                                <h5 class="font-weight-normal">{{ data.text }}</h5>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="pt-3">
                    <h4>Deskripsi</h4>
                    <div class="form-group">
                        <label for="deskripsiReport"></label>
                        <textarea v-model="formReport.detail_report" class="form-control" id="deskripsiReport" rows="3"></textarea>
                    </div>
                </div>
                <div class="float-right">
                    <div class="d-flex">
                        <button class="btn btn-secondary btn-grey mr-1 text-dark" @click="$bvModal.hide('report-quiz')">Cancel</button>
                        <button class="btn btn-warning text-white" @click="reportQuizViolations(idQuiz)">Submit</button>
                    </div>
                </div>
                <!-- <b-form-group v-slot="{ ariaReport }">
                    <b-form-radio-group
                        size="sm"
                        v-model="reportSelected"
                        :options="optionReport"
                        :aria-describedby="ariaReport"
                        name="plain-stacked"
                        plain
                        stacked
                    ></b-form-radio-group>
                </b-form-group> -->
            </div>
        </b-modal>
    </div>
</template>

<script>
import { getQuizAll, getQuizPopuler, reportQuiz } from "@/services/quiz/quiz.service";
import Swal from 'sweetalert2';

export default{
    data(){
        return {
            type : "",
            filter : null,
            quiz: [],
            currentPage : 1,
            perPage : 8,
            pageOptions: [8, 10, 25, 50, 100],
            userAuth : null,
            idQuiz : null,
            author:null,
            loading : false,
            formReport : {
                origin_quiz : null,
                report : null,
                detail_report : null,
            },
            optionReport : [
                {
                    text : 'Pelanggaran hak kekayaan intelektual',
                    value : 1,
                },
                {
                    text : 'Ujaran atau simbol kebencian',
                    value : 2,
                },
                {
                    text : 'Isi konten berisi tidak pantas',
                    value : 3,
                },
                {
                    text : 'Lainnya',
                    value : 4,
                },
            ],
        }
    },
    mounted(){
        if(this.$route.query.type == 'cuma-buat-kamu'){
            this.type = 'Cuma Buat Kamu'
        } else if(this.$route.query.type == 'konten-populer'){
            this.type = 'Konten Populer'
        } else if(this.$route.query.type == 'kategori-pilihan'){
            this.type = 'Kategori Pilihan'
        }

        this.getQuiz()
    },

    computed :{
        rows() {
            return this.quiz.length;
        },

        dataList(){
            return this.quiz.slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage)
        }
    },

    methods : {
        getQuiz(){
            this.loading = true;
            const type = this.$route.query.type
            if(type == "konten-populer") {
                getQuizPopuler()
                    .then((response) => {
                        if (response.status && response.status == true) {
                            this.quiz = response.data
                            this.loading = false;
                        } else {
                            this.loading = false;
                        }
                    })
            } else {
                getQuizAll()
                .then((response) => {
                    if(response.status && response.status == true){
                        this.quiz = response.data
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                })
            }
        },
        cutName(item){
            if(item != undefined){
                const name = item.split(" ")
                
                return name[0]  
            } else {
                return "-"
            }
        },
        getDate(item){
            var publish = new Date(item);

            var seconds = Math.floor((new Date() - publish) / 1000);

            var interval = seconds / 31536000;

            if (interval > 1) {
                return Math.floor(interval) + " years ago";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " months ago";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " days ago";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                return Math.floor(interval) + " hours ago";
            }
            interval = seconds / 60;
            if (interval > 1) {
                return Math.floor(interval) + " minutes ago";
            }
            return Math.floor(seconds) + " seconds ago";
        },
        detailQuiz(id, author) {
            this.$router.push({
                name: "Detail-Quiz",
                query: { key: id, author: author },
            });
                
        },

        share(id, author){
            this.linkShare ='https://creator.soca.ai/quiz/detail-quiz?key=' + id + '&author=' + author

            this.$copyText(this.linkShare).then(function () {
                alert('Berhasil copy link')
                }, function () {
                alert('Gagal untuk copy link')
                })
            // return this.linkShare
        },
        onCopy: function (e) {
            alert('Berhasil Copy link : ' + e.text)
        },

        onError: function () {
            alert('Gagal untuk copy link !')
        },
       
        getMinQuiz(length){
            
            let time = length * 30

            let min = Math.floor(time / 60);

            return min
        }, 

        reportQuizViolations(id){
            if(this.author == '0'){
                this.formReport.origin_quiz = "template"
            } else {
                this.formReport.origin_quiz = "creator"
            }
            reportQuiz(id, this.formReport)
            .then((response) => {
                if(response.status && response.status == true){
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Report sukses !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    this.idQuiz = null;
                    this.author = null;
                    console.log(response)
                } else {
                    this.$bvModal.hide('report-quiz')
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Report gagal !',
                        showConfirmButton: false,
                        timer: 1000
                    })
                    this.formReport = {
                        origin_quiz : null,
                        report : null,
                        detail_report : null,
                    },
                    this.idQuiz = null;
                    this.author = null;
                }
            })
        },

        modalReport(id,author){
            this.idQuiz = id
            this.author = author
            this.$bvModal.show('report-quiz')
        },
    }
}
</script>

<style scoped>
.header .search .form-control{
    padding: 10px 30px;
 }

 .header label{
    position: relative;
    width: 50%;
}

.header label:before {
    content: "";
    position: absolute;
    left: 10px;
    top: 0;
    bottom: 0;
    width: 20px;
    background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
}

.categories-card{
  width: 16rem;
}

.creator-info{
  border-radius: 12px;
  height: 25px;
  width: 70%;
  background-color: #F1F1F1;
}
.creator-info img {
  width: 18px;
  height: 18px;
}

.card .card-img-top {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
}

.card {
    border-radius: 10px 10px 10px 10px;
}

#report-quiz h3{
    font-size: 24px;
}

#report-quiz h4{
    font-size: 20px;
}

#report-quiz h5{
    font-size: 16px;
}

#report-quiz img{
    height: 24px;
    width: 20px;
}


#report-quiz input[type="radio"]{
    width: 22px;
    height: 22px;
}

#report-quiz .card, #report-quiz textarea{
    border-radius : 10px !important;
}

@media (max-width: 1367px){
    .categories-card{
        width: 14.8rem;
        border-radius: 10px 10px 10px 10px;
    }

    .card .card-img-top {
    width: 100%;
    height: 12.5rem;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
  }
}
</style>